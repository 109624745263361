import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { dark } from "@mui/material/styles/createPalette";
import { FC, useState } from "react";
import { Main } from "./Pages/Main";

const theme = createTheme({
    palette: {
        error: {
            main:'#d32f2f',
            light: '#fbd0d7',
            dark: '#c62828'
        }
    },
});
export const App:FC = () =>{
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Main />
        </ThemeProvider>
    )
};