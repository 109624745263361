import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useUploadPurchaseHistoryCsv } from 'hooks/useUploadDataCsv';
import { BasicAmount } from 'types/BasicAmount';
import type { PurchaseHistory } from "types/PurchaseHistory";
import { RakutenCardCSVDropArea } from './RakutenCardCSVDropArea';
import { localStrageConfig } from 'localStrageConfig';
import { BasicCircularProgress } from 'components/CircularProgress/BasicCircularProgress';
import { ErrorBox } from 'components/Box/ErrorBox';
import { Container } from '@mui/system';

type Props = {
  listYearMonth: Date;
  basicAmount : BasicAmount;
  updateBasicAmount: (newBasicAmount:BasicAmount) => Promise<boolean>;
  sendPurchaseHistories:(year:number,month:number,newPurchaseHistories:PurchaseHistory[]) => Promise<boolean>;
};

export const BasicAmountDialog: FC<Props> = (props)  => {
  const {basicAmount, updateBasicAmount, sendPurchaseHistories, listYearMonth} = props;
  const year = listYearMonth.getFullYear()
  const month = listYearMonth.getMonth() + 1
  const [open, setOpen] = useState(false);
  const {conversionCsvToPurchaseHistories} = useUploadPurchaseHistoryCsv();

  const refRent = useRef<HTMLInputElement>(null);
  const refGasFare = useRef<HTMLInputElement>(null);
  const refWaterFare = useRef<HTMLInputElement>(null);
  const refElectricalFare = useRef<HTMLInputElement>(null);

  const [ isRentError, setIsRentError ] = useState<boolean>(false);
  const [ isGasFareError, setIsGasFareError ] = useState<boolean>(false);
  const [ isWaterFareError, setIsWaterFareError ] = useState<boolean>(false);
  const [ isElectricalFareError, setIsElectricalFareError ] = useState<boolean>(false);
  const [ rentHelperText, setRentHelperText ] = useState<string>('');
  const [ gasFareHelperText, setGasFareHelperText ] = useState<string>('');
  const [ waterFareHelperText, setWaterFareHelperText ] = useState<string>('');
  const [ electricalFareHelperText, setElectricalFareHelperText ] = useState<string>('');

  const [ isLodeing, setIsLodeing ] = useState<boolean>(false);
	const [ isError, setIsError ] = useState<boolean>(false);

  const [newBasicAmount, setNewBasicAmount] = useState<BasicAmount>({
    id: basicAmount.id,
    target_year_month : `${year}/${month}/1`,
    rent: 0,
    water_fare: 0,
    gas_fare: 0,
    electrical_fare: 0,
    user_name:""
  })
  const [csvData ,setCsvData] = useState<any>([])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setNewBasicAmount({...basicAmount})
  },[open])

  const handleSaveClose = async () => {
    const rent = Number(refRent.current?.value)
    const gas_fare = Number(refGasFare.current?.value)
    const water_fare = Number(refWaterFare.current?.value)
    const electrical_fare = Number(refElectricalFare.current?.value)
    if (rent == undefined) {
      setIsRentError(true)
      setRentHelperText('Rentは必須項目です')
    }else{
      setIsRentError(false)
      setRentHelperText('')
    }
    if (gas_fare == undefined) {
      setIsGasFareError(true)
      setGasFareHelperText('GasFareは必須項目です')
    }else{
      setIsGasFareError(false)
      setGasFareHelperText('')
    }
    if (water_fare == undefined) {
      setIsWaterFareError(true)
      setWaterFareHelperText('WaterFareは必須項目です')
    }else{
      setIsWaterFareError(false)
      setWaterFareHelperText('')
    }
    if (electrical_fare == undefined) {
      setIsElectricalFareError(true)
      setElectricalFareHelperText('ElectricalFareは必須項目です')
    }else{
      setIsElectricalFareError(false)
      setElectricalFareHelperText('')
    }
    if(rent == undefined || gas_fare == undefined || water_fare == undefined || electrical_fare == undefined) return

    //データ送信開始
    setIsLodeing(true)
		const results = {
			updateBbasicAmount: false,
			sendPpurchaseHistories: false
		}
    results.updateBbasicAmount = await updateBasicAmount({...basicAmount,rent,gas_fare,water_fare,electrical_fare});
    if(csvData.length){
      const username = localStorage.getItem(
        localStrageConfig.username
      )
      if (username != null) {
        const newPurchaseHistories = conversionCsvToPurchaseHistories(csvData,year,month,username)
        results.sendPpurchaseHistories = await sendPurchaseHistories(year,month,newPurchaseHistories)
      }
    }
    setIsLodeing(false)
		if(results.updateBbasicAmount && results.sendPpurchaseHistories){
			setIsError(false)
			handleClose();
		}else{
			setIsError(true)	
		}
  };

  return (
		<>
			<Button variant="contained" onClick={handleClickOpen}>
				＋金額入力
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>金額入力フォーム</DialogTitle>
				<DialogContent>
					<DialogContentText>
						今月の各種金額を入力してください
					</DialogContentText>
					<TextField
						error={isRentError}
						helperText={rentHelperText}
						required
						autoFocus
						margin="dense"
						id="rent"
						label="家賃"
						type="number"
						fullWidth
						variant="standard"
						defaultValue={basicAmount.rent}
						inputRef={refRent}
					/>
					<TextField
							error={isGasFareError}
							helperText={gasFareHelperText}
							required
							margin="dense"
							id="gas_fare"
							label="ガス代"
							type="number"
							fullWidth
							variant="standard"
							defaultValue={basicAmount.gas_fare}
							inputRef={refGasFare}
						/>
					<TextField
							error={isWaterFareError}
							helperText={waterFareHelperText}
							required
							margin="dense"
							id="water_fare"
							label="水道代"
							type="number"
							fullWidth
							variant="standard"
							defaultValue={basicAmount.water_fare}
							inputRef={refWaterFare}
						/>
					<TextField
							error={isElectricalFareError}
							helperText={electricalFareHelperText}
							required
							margin="dense"
							id="electrical_fare"
							label="電気代"
							type="number"
							fullWidth
							variant="standard"
							defaultValue={basicAmount.electrical_fare}
							inputRef={refElectricalFare}
					/>
					<RakutenCardCSVDropArea setCsvData={setCsvData}/>
					<BasicCircularProgress isLodeing={isLodeing} />
					<ErrorBox isError={isError} >
						エラーが発生しました。再度お試しください。
					</ErrorBox>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleSaveClose}>保存</Button>
				</DialogActions>
			</Dialog>
		</>
  );
}