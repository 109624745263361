import { FC } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { LoginForm } from "./LoginForm";
import { NewPasswordForm } from "./NewPasswordForm";

type loginProps ={
  username:string
  password:string
}
type changePasswordProps = {
  newPassword:string
}
type Props = {
  login: (props:loginProps) => void;
  loginResult: boolean;
  changePassword: (props:changePasswordProps) => void;
  isFirstLogin: Boolean;
};


export const Login: FC<Props> = (props) => {
  const {login,loginResult, changePassword, isFirstLogin} = props

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isFirstLogin ? (
          <NewPasswordForm changePassword={changePassword} />
        ) : (
          <LoginForm login={login} loginResult={loginResult}/>
        )}
      </Box>
    </Container>
  );
};