import { Box } from "@mui/material";
import { FC } from "react";

type Props = {
    isError : boolean;
    children : React.ReactNode;
};

export const ErrorBox: FC<Props> = (props)  => {
    const { isError,children } = props;

    if (isError){
        return (
            <Box sx = {{width:'100%',mt:1,p:2,backgroundColor: 'error.light'}}>
                {children}
            </Box>
        )
    }else{
        return(
            <></>
        )
    }
}