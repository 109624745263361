import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

type Props = {
    isLodeing:Boolean
};

export const BasicCircularProgress: FC<Props> = (props)  => {
    const { isLodeing } = props;
    if (isLodeing){
        return(
            <Box display="flex" m={20} justifyContent="center">
                <Box>
                    <CircularProgress />
                </Box>
            </Box>
        )
    }else{
        return (
            <></>
        )
    }
}