import { FC, FormEvent, useContext, useRef, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { ErrorBox } from "components/Box/ErrorBox";

type loginProps ={
  username:string
  password:string
}
type Props = {
  login: (props:loginProps) => void;
  loginResult: boolean;
};

export const LoginForm: FC<Props> = (props) => {
  const {login,loginResult} = props
  const [ idFieldError, setIdFieldError ] = useState<boolean>(false);
  const [ idFieldHelperText, setIdFieldHelperText ] = useState<string>('');
  const [ passwordFieldError, setPasswordFieldError ] = useState<boolean>(false);
  const [ passwordFieldHelperText, setPasswordFieldHelperText ] = useState<string>('');
  
  const refId = useRef<HTMLInputElement>(null);
  const refPassword = useRef<HTMLInputElement>(null);

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const username = refId.current?.value
    const password = refPassword.current?.value
    if (!username) {
      console.log("Username is Empty");
      setIdFieldError(true)
      setIdFieldHelperText('idは必須項目です')
    }else{
      setIdFieldError(false)
      setIdFieldHelperText('')
    }
    if (!password) {
      console.log("Password is Empty");
      setPasswordFieldError(true)
      setPasswordFieldHelperText('passwordは必須項目です')
    }else{
      setPasswordFieldError(false)
      setPasswordFieldHelperText('')
    }
    if(!username || !password) return

    login({username: username,password: password})
  };
  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <ErrorBox isError={loginResult}>
        UsernameかPasswordが間違っています。
      </ErrorBox>
      <Box component="form" onSubmit={submitHandler} noValidate sx={{ mt: 1 }}>
        <TextField
          error={idFieldError}
          helperText={idFieldHelperText}
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoFocus
          inputRef={refId}
        />
        <TextField
          error={passwordFieldError}
          helperText={passwordFieldHelperText}
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          inputRef={refPassword}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
      </Box>
    </>
  );
};